<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2 d-flex justify-space-between"
      primary-title
      >Создание билета</v-card-title
    >

    <v-card-text class="text--primary mt-3">
      <v-text-field v-model="plate" label="ГРНЗ" outlined></v-text-field>
      <v-text-field
        v-model="dateTime"
        label="Дата и время"
        outlined
      ></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="submit"
        :disabled="plate.length < 2 || !isDateTimeValid"
        color="red"
        >Создать</v-btn
      >
      <v-btn text @click="$emit('close')">Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export default {
  data() {
    return {
      plate: "",
      dateTime: moment().format(dateTimeFormat),
    };
  },
  computed: {
    isDateTimeValid() {
      return moment(this.dateTime, dateTimeFormat).isValid();
    },
  },
  methods: {
    submit() {
      const ticket = {
        plate: this.plate,
        dateTime: moment(this.dateTime, dateTimeFormat).utc().toDate(),
      };
      this.createTicket(ticket).then(() => this.$emit("close"));
    },
    ...mapActions("tickets", ["createTicket"]),
  },
};
</script>