<template>
  <v-row>
    <v-col cols="2">
      <Tickets />
    </v-col>

    <v-col cols="5">
      <Entry :lane="1" class="mb-5" />
      <Entry :lane="2" />
    </v-col>

    <v-col cols="5">
      <Exit :lane="1" class="mb-5" />
      <Exit :lane="2" />
    </v-col>

    <v-dialog v-model="showDialog" width="1000">
      <TicketDetail />
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Tickets from "./Tickets.vue";
import TicketDetail from "./TicketDetail.vue";
import Entry from "./Entry.vue";
import Exit from "./Exit.vue";

export default {
  components: { Tickets, TicketDetail, Entry, Exit },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState({
      ticketDetail: (state) => state.tickets.ticketDetail,
    }),
  },
  methods: {
    ...mapActions("tickets", ["filterTickets"]),
    ...mapActions("unExits", ["filterUnExits"]),
  },
  watch: {
    ticketDetail: function (val) {
      this.showDialog = val !== null;
    },
  },
  mounted() {
    this.filterTickets("");
    this.filterUnExits("");
  },
};
</script>