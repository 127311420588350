<template>
  <v-card class="mx-auto" v-if="exit">
    <v-card-title class="d-flex justify-space-between">
      <span>Выезд #{{ exit.lane }}</span>
      <v-spacer />
      <v-chip v-if="exit.isRemoved">Выехал</v-chip>
      <v-chip v-else color="red" text-color="white">У шлагбаума</v-chip>
      <v-spacer />
      <span class="font-weight-light">{{ exit.dateTime | fulldate }}</span>
    </v-card-title>
    <template v-if="exit.image">
      <v-img
        :src="`data:image/jpeg;base64,${exit.image}`"
        class="ml-4 mr-4"
        height="140"
        contain
      ></v-img>
    </template>
    <template v-else>
      <p
        class="ml-4 mr-4 mt-2 mb-3 display-3 font-weight-thin text-center error--text"
      >
        Изображение отсутствует
      </p>
    </template>
    <v-card-text class="text--primary">
      <v-row class="mb-n7">
        <v-col cols="6">
          <v-text-field
            label="ГРНЗ"
            v-model="exit.plate"
            append-icon="done"
            @click:append="changePlate(exit)"
            @keyup.enter="changePlate(exit)"
            outlined
            :disabled="exit.isRemoved"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-slider
            v-model="exit.score"
            thumb-label="always"
            :thumb-size="24"
            :color="getScoreColor(exit.score)"
            readonly
            class="pt-6"
          ></v-slider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import commonMixin from "@/mixins/commonMixins";

export default {
  mixins: [commonMixin],
  props: ["lane"],
  computed: {
    ...mapGetters("exits", {
      getByLane: "getByLane",
    }),
    exit() {
      return this.getByLane(this.lane);
    },
  },
  methods: {
    ...mapActions("exits", ["changePlate"]),
  },
};
</script>