<template>
  <v-card>
    <v-card-title>
      <p class="title font-weight-regular">Нераспознанные выезды</p>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="search"
        label="ГРНЗ"
        single-line
        @input="onSearch"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!selected.length"
        @click="deleteDialog = true"
        icon
        color="red"
      >
        <v-icon>delete_forever</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :footer-props="footerProps"
        :items="unExits"
        item-key="id"
        show-select
        @input="onSelect"
      >
        <template v-slot:item.score="{ item }">
          <v-chip :color="getScoreColor(item.score)" text-color="white">
            <template>{{ item.score }}</template>
          </v-chip>
        </template>
        <template v-slot:item.dateTime="{ item }">
          {{ item.dateTime | fulldate }}
        </template>
        <template v-slot:item.detail="{ item }">
          <v-btn icon @click="openUnExitDetail(item.id)">
            <v-icon>wysiwyg</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="deleteDialog" width="300">
      <v-card>
        <v-card-title
          class="headline grey lighten-2 d-flex justify-space-between"
          primary-title
        >
          <span>Удаление выездов</span>
        </v-card-title>
        <v-card-text class="text--primary mt-3">
          <p class="subtitle-1">
            Вы действительно хотите удалить {{ selected.length }} выездов?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="onDelete">Да</v-btn>
          <v-btn text @click="deleteDialog = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import commonMixin from "@/mixins/commonMixins";

export default {
  mixins: [commonMixin],
  data() {
    return {
      headers: [
        { text: "Точность", value: "score" },
        { text: "Дата и время выезда", value: "dateTime" },
        { text: "ГРНЗ", value: "plate" },
        { text: "", value: "detail" },
      ],
      footerProps: {
        "items-per-page-options": [5, 10, 15, 20],
      },
      deleteDialog: false,
      selected: [],
    };
  },
  computed: {
    ...mapGetters("unExits", {
      unExits: "filteredUnExits",
    }),
  },
  methods: {
    ...mapActions("unExits", [
      "fetchUnExits",
      "openUnExitDetail",
      "filterUnExits",
      "deleteUnExits",
      "selectUnExit",
    ]),
    onSearch(value) {
      this.filterUnExits(value.toUpperCase());
    },
    onSelect(values) {
      this.selectUnExit(values.length === 1 ? values[0] : null);
    },
    onDelete() {
      this.deleteUnExits(this.selected.map((t) => t.id)).then(() => {
        this.selected = [];
        this.deleteDialog = false;
      });
    },
  },
  created() {
    this.fetchUnExits();
  },
};
</script>